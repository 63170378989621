@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scroll-snap-none {
    scroll-snap-type: none;
  }
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }
  .scroll-snap-y {
    scroll-snap-type: y mandatory;
  }
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/fonts/graphik-regular.woff2') format('woff2'),
    url('/fonts/graphik-regular.woff') format('woff');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/fonts/graphik-medium.woff2') format('woff2'),
    url('/fonts/graphik-medium.woff') format('woff');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/fonts/graphik-bold.woff2') format('woff2'),
    url('/fonts/graphik-bold.woff') format('woff');
  unicode-range: U+000-5FF;
}

:root {
  /* prettier-ignore */
  --kindest-font-sans: 'Graphik', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
  --kindest-primary-default: #6805e5;
  --kindest-primary-light: #ac71ff;
  --kindest-primary-ultralight: #f0e5ff;
  --kindest-primary-dark: #8733fe;
  --kindest-dark: #2f2f4b;
  --kindest-slate-gray: #8892b2;
  --kindest-white-fog: #cfd8e5;
  --kindest-light: #eceff4;
  --kindest-soft: #f3f6f9;
  --kindest-white: #fff;
  --kindest-success: #4ec6b7;
  --kindest-orange: #ff9666;
  --kindest-error: #fd737b;
  --kindest-warning: #ff9666;
  --chariot-light-blue: #35bbf4;
  --chariot-darh-blue: #16a5e2;
  --reach-tooltip: 1;
  --reach-tabs: 1;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-weight: 300;
  /* font-size: 62.5%; */
}

html,
body {
  font-family: var(--kindest-font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--kindest-dark);
  background: #fff;
  scroll-behavior: smooth;
  /* scroll-padding-top: var(--header-height); */
}

body,
#__html {
  position: relative;
  min-height: 100%;
  background: #fff;
}

#portal {
  z-index: 999;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  appearance: none;
  outline: none;
  user-select: none;
  font: inherit;
}

input,
textarea {
  border: none;
  background: none;
  appearance: none;
  font-family: var(--kindest-font-sans);
  font-weight: inherit;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}

svg {
  display: inline-block;
}

ul {
  list-style: none;
}

strong {
  font-weight: 400;
}

[data-reach-tooltip] {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  padding: 15px 20px;
  background: var(--kindest-dark);
  border-radius: 2px;
}

[data-reach-menu-item] {
  padding: 0;
}

[data-reach-menu-item][data-selected] {
  background: none;
  color: initial;
  outline: none;
}

.focus-ring {
  box-shadow:
    0 0 0 1px rgb(50 151 211 / 30%),
    0 1px 1px 0 rgb(0 0 0 / 7%),
    0 0 0 4px rgb(50 151 211 / 30%);
}
